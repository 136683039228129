var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"header",staticClass:"header",class:{
    'header--active': _vm.isScrolled || _vm.isActive,
    'header--authorized': _vm.$app.user && _vm.$app.config.current.lmsLoginUrl,
  }},[_c('div',{staticClass:"header__wrapper"},[_c('div',{staticClass:"header__body"},[_c(_vm.$route.path === '/' ? 'span' : 'NuxtLink',{tag:"Component",staticClass:"header__logo",attrs:{"to":_vm.$route.path === '/' ? '' : '/',"no-prefetch":""}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"header__icon not-lazy",attrs:{"alt":"logo","data-src":require(("~/assets/" + (_vm.$app.config.common.currentCountry) + "/svg/ebac-logo.svg")),"data-not-lazy":""}})]),_vm._v(" "),_c('ul',{staticClass:"header__links"},[_c('li',[_c('NuxtLink',{class:['header__link', { 'nuxt-link-active': _vm.coursesActive }],attrs:{"to":"/cursos","data-test":"course-header-link","no-prefetch":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.courses')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.courses')))])])],1),_vm._v(" "),(
            !_vm.isBr &&
            _vm.$app.config.current.b2bLinks &&
            _vm.$app.config.current.b2bLinks['corporateEducation'] &&
            _vm.$app.config.current.b2bLinks['corporateEducation'].url
          )?_c('li',[_c('a',{staticClass:"header__link",attrs:{"href":_vm.$app.config.current.b2bLinks['corporateEducation'].url,"no-prefetch":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.toPartners.corporateEducation')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.toPartners.corporateEducation')))])])]):_vm._e(),_vm._v(" "),(_vm.isBr)?_c('li',[_c('NuxtLink',{staticClass:"header__link",attrs:{"to":"/sale/construa-seus-sonhos","no-prefetch":""},nativeOn:{"click":function($event){return (function () { return _vm.$gtm.push({
                  event: 'autoEvent',
                  eventCategory: 'click',
                  eventAction: 'sale',
                  eventLabel: 'header',
                }); }).apply(null, arguments)}}},[_c('span',[_vm._v("BLACK FRIDAY 2024")]),_vm._v(" "),_c('span',[_vm._v("BLACK FRIDAY 2024")])])],1):_vm._e(),_vm._v(" "),(_vm.$app.config.current.withWebinars)?_c('li',[_c('NuxtLink',{staticClass:"header__link",attrs:{"data-test":"webinar-header-link","to":"/webinars","no-prefetch":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.events')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.events')))])])],1):_vm._e(),_vm._v(" "),_c('li',[(_vm.$app.config.current.withBlog)?_c('a',{staticClass:"header__link",attrs:{"href":_vm.$app.config.current.blogUrl,"data-test":"blog-header-link"}},[_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.blog')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.blog')))])]):_vm._e()]),_vm._v(" "),_c('li',[_c('NuxtLink',{class:['header__link', { 'nuxt-link-active': _vm.aboutUsActive }],attrs:{"to":"/about-us","data-test":"about-header-link","no-prefetch":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.company')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('rt0822.header.company')))])])],1)]),_vm._v(" "),(_vm.$app.config.current.phoneLink)?_c('div',{staticClass:"header__phone"},[_c('a',{attrs:{"href":_vm.$app.config.current.phoneLink}},[_vm._v(_vm._s(_vm.$app.config.current.phone))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"header__actions"},[_c('ClientOnly',[(!_vm.$app.user && _vm.$app.config.current.lmsLoginUrl)?_c('div',{staticClass:"header__userarea"},[_c('EBtn',{staticClass:"header__login",attrs:{"color":"secondary","href":_vm.$app.config.current.lmsLoginUrl,"target":"_blank","rect":"","size":"s","label":_vm.$t('rt0822.header.login')},on:{"click":function () { return _vm.$gtm.push({
                    event: 'autoEvent',
                    eventCategory: 'click',
                    eventAction: 'login',
                    eventLabel: 'cursos_page',
                  }); }}})],1):_c('div',{staticClass:"header__userarea"},[_c('a',{staticClass:"header__profile",attrs:{"href":_vm.$app.config.current.lmsUrl,"title":_vm.$app.user.name,"target":"_blank"}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"not-lazy",attrs:{"data-src":require("assets/svg/ic-user.svg"),"alt":"profile","data-not-lazy":""}})]),_vm._v(" "),_c('button',{on:{"click":_vm.logout}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"header__logout not-lazy",attrs:{"data-src":require("assets/svg/ic-log-out.svg"),"alt":"logout","data-not-lazy":""}})])])]),_vm._v(" "),_c('button',{ref:"button",staticClass:"header__menu-button",class:{
            'header__menu-button--active': _vm.isActive,
          },on:{"click":_vm.open}},[_c('div',{staticClass:"header__menu-bar"}),_vm._v(" "),_c('div',{staticClass:"header__menu-bar"}),_vm._v(" "),_c('div',{staticClass:"header__menu-bar"})])],1)],1),_vm._v(" "),_c('div',{ref:"menu",staticClass:"header__menu",class:{
        'header__menu--active': _vm.isActive,
      }},[_c('ClientOnly',[(_vm.$app.user && _vm.$app.config.current.lmsLoginUrl)?_c('div',{staticClass:"header__menu-userarea"},[_c('span',{staticClass:"header__menu-username"},[_vm._v(_vm._s(_vm.$app.user.name))])]):_vm._e()]),_vm._v(" "),(_vm.isMobile)?_c('ul',{staticClass:"header__menu-links"},[_c('li',[_c('NuxtLink',{staticClass:"header__link",attrs:{"to":"/cursos","data-test":"course-header-link","no-prefetch":""}},[_vm._v(_vm._s(_vm.$t('rt0822.header.courses')))])],1),_vm._v(" "),(_vm.isBr)?_c('li',[_c('NuxtLink',{staticClass:"header__link",attrs:{"to":"/sale/construa-seus-sonhos","no-prefetch":""},nativeOn:{"click":function($event){return (function () { return _vm.$gtm.push({
                  event: 'autoEvent',
                  eventCategory: 'click',
                  eventAction: 'sale',
                  eventLabel: 'header',
                }); }).apply(null, arguments)}}},[_vm._v("BLACK FRIDAY 2024")])],1):_vm._e(),_vm._v(" "),(_vm.$app.config.current.b2bLinks)?_c('li',[_c('a',{staticClass:"header__link",attrs:{"href":_vm.$app.config.current.b2bLinks['corporateEducation'].url,"no-prefetch":""}},[_vm._v(_vm._s(_vm.$t('rt0822.header.toPartners.corporateEducation')))])]):_vm._e(),_vm._v(" "),(_vm.$app.config.current.withWebinars)?_c('li',[_c('NuxtLink',{staticClass:"header__link",attrs:{"to":"/webinars","data-test":"webinar-header-link","no-prefetch":""}},[_vm._v(_vm._s(_vm.$t('rt0822.header.events')))])],1):_vm._e(),_vm._v(" "),(_vm.$app.config.current.withBlog)?_c('li',[_c('a',{staticClass:"header__link",attrs:{"href":_vm.$app.config.current.blogUrl,"data-test":"blog-header-link"}},[_vm._v(_vm._s(_vm.$t('rt0822.header.blog')))])]):_vm._e(),_vm._v(" "),_c('li',[_c('NuxtLink',{staticClass:"header__link",attrs:{"to":"/about-us","data-test":"about-header-link","no-prefetch":""}},[_vm._v(_vm._s(_vm.$t('rt0822.header.company')))])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"header__menu-phone"},[_c('a',{attrs:{"href":_vm.$app.config.current.phoneLink}},[_vm._v(_vm._s(_vm.$app.config.current.phone))])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }